
.insta-background {
  position: relative;
  background-color: white;
  border-radius: 20px;

}

.top-half {


  .top {
    height: 1rem;

    border-radius: 10px;

    .top-logo {

      .logo {
        height: 15rem;
        padding: 1.5rem;
      }

    }

    .top-name {
      height: 15rem;

      .name-parent {
        height: 10rem;
        background-color: white;
        height: 10rem;

        padding-right: 3rem;
        padding-left: 7rem;
        margin-left: -5rem;

        z-index: 10;

        .name-text {
          font-size: 5rem;
          color: #1E4537; // secondary dark green
        }

        border-bottom-right-radius: 40px;
      }

      .dog-centered {
        z-index: 20;
        height: 10rem;
        border-bottom-left-radius: 9999px;
        border-top-left-radius: 9999px;
        border-bottom-right-radius: 9999px;
      }
    }
  }

  .middle {

  }
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.pt-lots {
  padding-top: 6rem;
}

.pl-lots {
  padding-left: 7rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pl-8 {
  padding-left: 2rem;
}

.items-center {
  align-items: center;
}

.h-12 {
  height: 3rem;
}

.flex-col {
  flex-direction: column;
}

.mb-8 {
  padding-bottom: 3rem;
}

.bg-salmon {
  background-color: salmon;
}

.bg-gray {
  background-color: gray;
}

.flex {
  display: flex;
}

.absolute {
  position: absolute;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}


.relative {
  display: relative;
}

.flex-row {
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-evenly {
  justify-content: space-evenly;
}

.div-space {
  width: 100px;
  height: 100px;
}

.items-center {
  align-items: center;
}

.-mt-60 {
  margin-top: -15rem;
}

.bottom-half {
  min-height: 170px;

  background-color: #1E4537;
  width: 100%;
  height: 100%;
  margin-top: -5px;
  padding-bottom: 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

}

.lip {
  height: 4rem;
}

.lip-right {
  transform: scaleX(-1);
}

.w-1080px {
  width: 1080px;
}

.pills-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 15px 0 0 35px;
}

.more-text {
  position: absolute;
  bottom: 35px;
  right: 15px;
  color: white;
}

.social-image-share {
  border: 1px solid #bebebe;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2.4rem 3.2rem -1.6rem rgba(0, 0, 0, 0.25), inset 0 0.4rem 0 #C1FF3F;
}
