@import "petdna-redwood/src/scss/variables/palette";

.pill-outer {
  .pill {

    font-size: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    border-radius: 9999px;
    color: #1E4537; // secondary dark green
  }

  .percentage {
    font-size: 2rem;
    color: #1E4537; // secondary dark green
    padding-left: 1rem;
  }

}

.flex {
  display: flex;
}

.justify-normal {
  justify-content: normal;
}
