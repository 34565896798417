.name-modal {
  .name-modal_title .name-modal-subtitle {
    color: #FFF;
  }
  
  .name-modal-header {
    justify-content: center;
    background: #1E4537;
    .modal-title {
      flex: 1 1 auto;
    }
  }
  
  .name-modal-body {
    background: #1E4537;
  }
  
  .modal-header .btn-close {
    display: none;
  }
  
  .modal_close_button {
    display: flex;
    margin-left: .8rem;
    justify-content: center;
    align-items: center;
    width: 3.25rem;
    height: 3.25rem;
    flex-shrink: 0;
    border-radius: 1.875rem;
    background: #FFF;
    border: none;
  }
  
  .modal_close_button:hover {
    background: #C9C9C9;
  }

  .name-icon-close {
    color: #1E4537;
  }
  
  .name-modal_title {
    color: #FFF;
    text-align: center;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.015rem;
    margin-bottom: 0;
  }
  
  .modal-subtitle {
    margin-bottom: 4rem;
    color: #FFF;
    text-align: center;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 300;
    line-height: 2.5rem;
    letter-spacing: -0.00938rem;
  }
  
  .name_modal_input_name {
    padding: 1rem 2rem;
    width: 100%;
    height: 4.5rem;
    border-radius: 0.75rem;
    border-bottom: 0.2px solid #E9FEB2;
    background: rgba(20, 37, 31, 0.60);
    color: #FFF;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.25rem;
  }
  
  .name_modal_input_name::placeholder {
    color: #C9C9C9;
  }
  
  .name-modal-footer {
    border: none !important;
    background: #1E4537;
  }
  
  .btn-done {
    height: 4.5rem !important;
    width: 100% !important;
    border-radius: 2rem;
    background: #E9FEB2;
    color: #1E4537;
    text-align: center;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
  }
  
  .btn-done:disabled {
    background: #B3E9FEB2;
    color: #801E4537;
  }
  
  .length-warning {
    color: red;
    font-size: 1.25rem;
    margin-top: 0.75rem;
    margin-left: 0.25rem;
    width: 100%;
  }
}
