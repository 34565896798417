.dashboard-nav {
  height: 48px;
  margin-bottom: 0;
}

.option {
  margin-left: 1rem;
  text-decoration: none;
  align-items: center;
  align-content: center;
  padding-block: 1px;
  padding-inline: 6px;
}

.option-text {
  margin: 0;
  margin-left: 10px;
}

.option.active {
  border-bottom: 3px solid #1E4537 !important;
}

.unread-count-badge {
  background-color: #1E4537;
  color: #c1ff3f;
  margin-left: 0.5rem;
  border-radius: 61%;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}