.box {
  background-color: salmon;
  width: 100px;
  height: 100px;
}

.flex {
  display: flex;
}


.parent {
  position: relative;

  .cover {
    width: 1080px;
    height: 1350px;
    background-color: red;
    position: absolute;
  }

  .behind {
    position: absolute;

  }
}