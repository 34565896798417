@import 'src/styles/mixins';
@import "src/styles/palette";

@keyframes slideInUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.modal-social-share.show {
  animation: fadeIn 0.3s ease-in;

  @include media-breakpoint-down(md) {
    animation: slideInUp 0.3s ease-out;
  }

}

.modal-social-share {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background: rgba(59, 85, 74, 0.8);

  
  margin: 0;
  padding: 0;


  @include media-breakpoint-down(md) {
    //overflow-y: hidden;

  }
}

.modal-social-share-body {

  @include media-breakpoint-down(md) {
    padding: 0 !important;
  }
}

.modal-social-share-content {
  background: #fff;
  padding: 20px;
  border-radius: 2rem;
  overflow-y: auto;
  max-width: 1920px;
  z-index: 100;
  position: absolute;


  @include media-breakpoint-up(lg) {

    min-width: 1100px;
    z-index: 100;
    width: 40%;
    padding: 10px;

    margin-left: auto;
    margin-right: auto;
    left: 50%;

    top: 50%;
    transform: translate(-50%, -50%);
  }

  @include media-breakpoint-down(md) {
    position: absolute;
    top: 10px;
    height: 100vh;
    width: 100vw;
    border-radius: 0 !important;
    z-index: 100;
  }
}

.custom-modal-style {
  width: 100% !important;

  @include media-breakpoint-down(md) {
    margin-top: 0;
    margin-left: 0;
  }
}

.modal-social-share-dialog {
  width: 100%;

}


.modal-social-share-header {
  padding: 0;
  margin-bottom: 20px;
}

.btn-rounded-icon {
  border-radius: 6px;
}

.btn-social-list {
  background-color: transparent;
  border: none;

}

.chart-img {
  max-height: 100%;
  width: 100%;
  margin-bottom: 5vh;
}

// Flexbox utility
.flex-justify-start {
  display: flex;
  justify-content: start;
  align-items: center;
}

// Spacing utility
.mt-12 {
  margin-top: 3rem;
}

.btn-rounded-icon {
  background-color: #F1EFEA;
  color: white;
  border: none;
  padding: 0 0 0 0;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transition: all 0.2s ease;

  .icon {
    background-color: white;

  }
}

.btn-rounded-icon:hover {
  background-color: grey;
}

.btn-rounded-icon:active {
  box-shadow: 0 0 2px darkslategray;
  transform: translateY(2px);
}

.btn-rounded-icon {
  margin-bottom: 10px;
}

.round-6 {
  border-radius: 70%;
}


.social-share-container {
  margin-top: -25px;
  margin-left: -20px;
}

.rounded-lg {
  border-radius: 2rem !important; /* or any other value you prefer */
}

.text-box-wrapper {
  height: auto;
  min-height: 22.5rem;
  overflow-y: scroll;
  padding: 15px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.text-outer-box {
  border-radius: 20px;
  border: 1px solid #1E4537;

  //@include media-breakpoint-down(md) {
  //  margin-top: 5dvh;
  //}
}

.text-inner-box {
  background-color: #E9FEB2;
  display: flex;
  flex-direction: row;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  justify-content: space-between;
  padding: 10px 15px;

  img {
    cursor: pointer;

  }

  button {
    border: none;
    background-color: transparent;
  }
}

.button-list-wrapper {
  margin-left: 20px;
}


// Swiper styles
.mySwiper {
  width: 100%;
  height: 100%;
  max-width: 500px;
  min-width: 100px;


  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
}

.img-dog-avatar {

  border-radius: 50%;
  height: 30px;
  width: 30px;


}

.btn-close {
  z-index: 10;
}

.span-clipped {
  display: block;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.social_share_modal_btn_container {
  background-color: #fff;
  margin: 0;
  padding: 1.5rem;
  position: fixed;
  bottom: 0;
  z-index: 1100;
  width: 100%;
  max-width: 100% !important;
}

.social-image-shar {
  border-radius: 30px;
}

.icon-button-wrapper {
  display: flex;
  justify-content: start;
  align-items: center
}

.envelop-btn {
  margin-left: 20px;
  margin-top: 10px;
  margin-right: 5px;
}

.icon-button-text {
  margin-left: 20px;
}

.text-box-container {

  @include media-breakpoint-down(sm) {
    margin-bottom: 20vh;
  }
}
