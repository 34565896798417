@import 'petdna-redwood/src/scss/petdna-redwood';
@import 'petdna-redwood/dist/css/carousel.min.css';
@import 'petdna-lib-uicomponents/dist/style.css';

body {
  overflow-x: hidden !important;
}

.fill-screen {
  align-items: center;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100svh;
}

.fixed-top {
  transition: top .3s ease-in-out;

  &.fixed-hidden {
    top: -5.6rem;
  }
}

.con-primary {
  background-color: white;
  border-radius: 2.4rem;
  box-shadow: 0 2.4rem 3.2rem -1.6rem rgba(0, 0, 0, 0.25), inset 0 .4rem 0 #C1FF3F;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 0;
  text-decoration: none;
  transition: box-shadow 300ms ease-in-out, margin-bottom 300ms ease-in-out, margin-top 300ms ease-in-out;

  .con-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 2.4rem;
    z-index: 10;

    .con-title {
      font-size: $font-size-base;
      line-height: $line-height-base;
      margin: 0;
    }

    .con-header-actions {
      align-items: center;
      display: flex;
      gap: 1.6rem;
    }
  }

  .con-body {
    flex-grow: 1;

    .con-img {
      flex-grow: 1;
      min-height: 20rem;
      max-height: 45rem;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
        overflow: hidden;
        width: 100%;
        max-height: 45rem;
      }

      .profile-img {
        .icon {
          min-height: 12.8rem;
          min-width: 12.8rem;
        }
      }
    }

    .con-content {
      border-radius: 2.4rem;
      background-color: $white;
      flex-shrink: 0;
      position: relative;
      z-index: 1;

      &.overlap-top {
        margin-top: -2.4rem;
      }

      &.overlap-bottom {
        margin-bottom: -2.4rem;
      }
    }

    .con-footer {
      background-color: $pet-secondary-dark-green;
      border-radius: 0 0 2.4rem 2.4rem;
      color: $white;
      padding: 4.8rem;
      padding-top: 6.4rem;

      @include media-breakpoint-down(xl) {
        padding: 2.4rem;
        padding-top: 4.8rem;
      }
    }
  }
}

a.con-primary:hover {
  box-shadow: 0 3rem 6rem 0 rgba(30, 69, 55, 0.45), inset 0 .4rem 0 #C1FF3F;
  margin-bottom: 1.6rem;
  margin-top: -1.6rem;
  z-index: 5;
}

.page-con {
  @include media-breakpoint-down(lg) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.page-info-con {
  border-radius: 2.4rem;
  box-shadow: 0 2.4rem 3.2rem -1.6rem rgba(0, 0, 0, 0.25);

  .page-con-header {
    // override to match figma design
    background-color: #d5e999;
    //background-color: $pet-secondary-light-green;
    color: $pet-secondary-dark-green;
    border-radius: 2.4rem 2.4rem 0 0;
    padding: 1.6rem 2.4rem;
    padding-bottom: 5.6rem;
  }

  .page-con-body {
    background-color: white;
    border-radius: 2.4rem;
    display: flex;
    flex-direction: column;
    margin-top: -4rem;
  }
}

.offcanvas-end {
  border-radius: 2.4rem 0 0 2.4rem;
}

.breed-pill-tooltip-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  justify-content: start;
  align-items: center;

  @include media-breakpoint-down(sm) {
    text-overflow: ellipsis;
    width: inherit;
    overflow: hidden;
    display: block;
  }
}

.breed-pill-tooltip {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.breed-pill {
  background-color: $pet-mid-coral;
  border-radius: 2.4rem;
  color: $black;
  display: flex;
  padding: .8rem 1.6rem;

  @include media-breakpoint-down(sm) {
    text-overflow: ellipsis;
    width: inherit;
    overflow: hidden;
    display: block;
  }

  &.secondary {
    background-color: $pet-dark-green-20;
    color: $pet-secondary-dark-green;
  }

  .placeholder-wave {
    display: block;
    width: 12rem;
  }
}

.small-text {
  color: $ui-opacity-black-60;
}

.initial-tables {
  tbody {
    border-width: initial !important;
  }

  tr {
    border-width: initial !important;
  }

  td {
    border-width: initial !important;
    word-break: break-word;
    min-width: 70px;
  }
}

.section-link {
  margin-left: 6.4rem !important;
  margin-right: 6.4rem !important;
  align-self: normal;

  @include media-breakpoint-up(lg) {
    align-self: flex-start;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.body-no-scroll {
  position: fixed; 
  inline-size: 100%;
  overflow-y: scroll; 
}

#pmLink {
  //visibility: hidden;
  background-color: #1e4537;
  border: none;
}

a.disable {
  pointer-events: none;
}

.only-lg-up, .only-lg-up.d-flex {
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.only-lg-down, .only-lg-down.d-flex {
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}

.only-md-up, .only-md-up.d-flex {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.only-md-down, .only-md-down.d-flex {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}
