@use 'src/styles/_minimalRedwood.scss' as redwood;
@use 'src/styles/mixins';

.header-promo-banner-lihp {
  animation: fade-in .8s;
  @include mixins.fade-in-mixin();
}

.header-container {
  border-bottom: 1.5px solid #1E453710;

  @include redwood.media-breakpoint-down(lg) {
    border: none;
  }

  .nav-link:focus-visible {
    outline: 0.2rem solid #d37730;
    outline-offset: 1px;
  }

  .nav-link {
    color: #1E4537;
  }
}

.navbar-site {
  .language-switcher-li {
    position: absolute;
    right:0;
    transform: translateX(100%);

    @include redwood.media-breakpoint-up(lg) {
      --rwd-btn-active-border-color: #ECE9E4;
      max-width: 66px;
      z-index: 15;
      border-left: 2px solid var(--rwd-btn-active-border-color);
      --switcher-title-color: #F5F5F5;
      --switcher-top-offset: 1rem;
      --switcher-button-color: #5E5D5B;
    }

    
    @media (min-width: 992px) and (max-width: 1137px) {
      position: sticky;
      transform: translate(0);
    }

    @media (min-width: 1200px) and (max-width: 1303px) {
      position: sticky;
      transform: translate(0);
    }

    @media (min-width: 1400px) and (max-width: 1485px) {
      position: sticky;
      transform: translate(0);
    }
  }
}

.btn-menu-close {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

#mobileHeaderMenu {
  background-color: redwood.$pet-secondary-dark-green;
  color: redwood.$pet-lead-green;

  .language-switcher-li {
    --switcher-button-color: #C1FF3F;
  }
}

.profile-btn {
  border: .1rem solid redwood.$pet-secondary-dark-green !important;
  text-decoration: none;
}

.name-placeholder {
  width: 15rem;
}

.btn.btn-link.cart-link {
  -webkit-appearance: attachment;
  background-color: transparent;
  border: none;
}

.icon-menu {
  margin-top: -16px;
  --rwd-icon-min-height: 4.5rem !important;
  --rwd-icon-min-width: 4.5rem !important;
}

.main-navbar {
  padding: 0 !important;
}

.dashboard-nav-container {
  margin-right: auto;
}

.mobile-unread-count-badge {
  background-color: #c1ff3f;
  color: #1E4537;
  margin-left: 0.5rem;
  border-radius: 61%;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


a.skip-main-content {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}

a.skip-main-content:focus,
a.skip-main:active {
  color: #000000;
  font-weight: bold;
  background-color: #ffffff;
  left: 0;
  top: 0;
  width: 150px;
  height: auto;
  overflow: auto;
  padding: 20px;
  border: 1px solid;
  text-align: center;
  font-size: 1.4rem;
  z-index: 999;
  text-decoration: none;
}

