@import 'bootstrap/scss/functions';
@import "petdna-redwood/src/scss/variables/palette";
@import 'petdna-redwood/src/scss/variables/overrides';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@mixin fade-in-mixin ($name: fade-in, $from: 0, $to: 100) {
  @keyframes #{$name} {
    from {
      opacity: $from;
    }
    to {
      opacity: $to;
    }
  }
}

@mixin loading-mixin  ($name: skeleton-loading) {
  @keyframes #{$name} {
    0% {
      background-color: rgb(30 69 55 / 15%);
    }
    100% {
      background-color: rgb(30 69 55 / 55%);
    }
  }
}